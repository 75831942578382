<script>
export default {
};
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="flex-grow-1 align-self-center">
            <h5 class="font-size-14 text-danger">Disclaimer</h5>
            <p class="mb-0 text-muted">
              The content of this webpage should not be construed as investment
              advice and does not constitute any solicitation, recommendation,
              endorsement, or offer to buy or sell any securities or other
              financial instruments. It is for general purposes only and does not
              take into account your individual needs, investment objectives and
              specific financial circumstances.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>