<script>
export default {
  components: {
  },
  props: ['assetType', 'apiData', 'metaData', 'selectedScope'],
  data() {
    return {
      pageSize: 10,
      scopes: {
        intraday: {
          value: "intraday",
          snake: "intraday",
          train: "Intraday",
          kebab: "intraday"
        },
        shortterm: {
          value: "shortterm",
          snake: "short_term",
          train: "Short-Term",
          kebab: "short-term"
        },
        mediumterm: {
          value: "mediumterm",
          snake: "medium_term",
          train: "Medium-Term",
          kebab: "medium-term"
        },
        longterm: {
          value: "longterm",
          snake: "long_term",
          train: "Long-Term",
          kebab: "long-term",
        }
      },
    };
  },
  methods: {
    setBadgeClass(itm, term) {
      return {
        'badge badge-score-label badge-strong-buy': itm['score_labels']['score_label_' + term] === 'strong buy',
        'badge badge-score-label badge-strong-sell': itm['score_labels']['score_label_' + term] === 'strong sell',
        'badge badge-score-label badge-buy': itm['score_labels']['score_label_' + term] === 'buy',
        'badge badge-score-label badge-sell': itm['score_labels']['score_label_' + term] === 'sell',
        'badge badge-score-label badge-neutral': itm['score_labels']['score_label_' + term] === 'neutral'
      }
    },
    truncate(value, length) {
      if (window.innerWidth < 768) {
        if (value.length > length) {
          return value.substring(0, length) + "…";
        } else {
          return value;
        }
      } else {
        return value;
      }
    },
    formatPrice(price) {
      if (price >= 1) {
        return new Intl.NumberFormat(
          'en-US', 
          { 
            style: 'currency', 
            currency: 'USD', 
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }
        ).format(price)
      }
      else {
        return new Intl.NumberFormat(
          'en-US', 
          { 
            style: 'currency', 
            currency: 'USD', 
            minimumFractionDigits: 4,
            maximumFractionDigits: 4
          }
        ).format(price)
      }
    },
    formatPercent (percent) {
      return new Intl.NumberFormat(
        'en-US', 
        { 
          style: 'percent', 
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          signDisplay: 'always'
        }
      ).format(percent)
    },
    getScoreColor(score) {
      if(score < 0) {
        return '#DD3444'
      } else {
        return '#28A745'
      }
    },
    getScoreEmoji(score) {
      if (score >= .4) return '🚀'
      else if (score <= -.4) return '☢️'
      else if (score >= .2 && score < .4) return '🔥'
      else if (score <= -.2 &&	score > -.4) return '⚡'
      else if (score > -.2 && score < .2) return '🌱'
      else return '🌱'
    },
    getBadgeClass(score) {
      return {
        'badge badge-score-label badge-strong-buy': score >= 40,
        'badge badge-score-label badge-strong-sell': score <= -40,
        'badge badge-score-label badge-buy': (score >= 20) && (score < 40),
        'badge badge-score-label badge-sell': (score <= -20) && (score > -40),
        'badge badge-score-label badge-neutral': (score > -20) &&	(score < 20)
      }
    },
    getBadgeClassStrong(score) {
      return {
        'badge badge-score-strong badge-strong-buy': score >= 40,
        'badge badge-score-strong badge-strong-sell': score <= -40,
        'badge badge-score-strong badge-buy': (score >= 20) && (score < 40),
        'badge badge-score-strong badge-sell': (score <= -20) && (score > -40),
        'badge badge-score-strong badge-neutral': (score > -20) &&	(score < 20)
      }
    },
    getBadgeLabel(score) {
      if (score >= 40) return 'strong buy'
      else if (score <= -40) return 'strong sell'
      else if (score >= 20 && score < 40) return 'buy'
      else if (score <= -20 &&	score > -40) return 'sell'
      else if (score > -20 && score < 20) return 'neutral'
      else return ''
    },
    getColorClass(number) {
      if (number < 0) return 'color-down'
      else if (number >= 0) return 'color-up'
      else return ''
    },
    toSparklineSeries(array, symbol) {
      return [{
        name: symbol,
        data: array
      }]
    },
    getSparklineColor(item, days) {
      if (days == 7) {
        return (item.rel_deltas.rel_delta_7_days < 0) ? ['#ff0000'] : ['#008000']
      }
      else if (days == 30) {
        return (item.rel_deltas.rel_delta_30_days < 0) ? ['#ff0000'] : ['#008000']
      }
      else if (days == 200) {
        return (item.rel_deltas.rel_delta_200_days < 0) ? ['#ff0000'] : ['#008000']
      }
    },
    getSparklineChartOptions(item, days) {
      return {
        chart: {
          type: 'area',
          sparkline: {
            enabled: true
          }
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        colors: this.getSparklineColor(item, days),
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.45,
            opacityTo: 0.05,
            stops: [25, 100, 100, 100]
          },
        },
        tooltip: {
          enabled: false,
        }
      }
    },
    scoreTransformed(score) {
      return Math.round(score * 100) || 0
    },
  },
}
</script>

<style scoped>

.dash-card {
  background-color: #fff;
  box-shadow: none;
}

.td-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 0;
}
.asset-price {
  font-size: 27px;
  font-weight: 300;
}
.bulldata-score {
  font-size: 24px;
  font-weight: 400;
}
.fira {
  font-family: "Fira Code", "Lucida Console", monospace;
}
.card-body-listings {
  padding: 12px;
}
.color-down {
  color: #ff0000;
}
.color-up {
  color: #008000;
}
.rel-delta {
  font-size: 18px;
  font-weight: 300;
}
.badge-score-label {
  font-family: "Fira Code", "Lucida Console", monospace;
  font-size: 13px;
  font-weight: 300;
  padding: 0.5em 0.5em;
}
.badge-score-strong {
  font-family: "Fira Code", "Lucida Console", monospace;
  font-size: 13px;
  font-weight: 400;
  padding: 0.5em 0.5em;
}
.badge-buy {
  background-color: #008000;
}
.badge-sell {
  background-color: #ff0000;
}
.badge-strong-buy {
  background-color: #008000;
}
.badge-strong-sell {
  background-color: #ff0000;
}
.badge-neutral {
  background-color: #5f6875;
}

.table-first-row {
  border-top: 1px solid;
}

.score-header {
  font-weight: 600;
  text-transform: uppercase;
}

.score-row {
  font-family: "Lucida Console", "Courier New", monospace;
}

.sticky-col {
  position: sticky;
  left: 0;
  background-color: #ffffff;
}

.overapex {
  z-index: 12;
}

</style>

<template>
  <div class="table-responsive">
    <table
      class="table table-centered align-middle table-sm text-nowrap"
    >
      <thead>
        <tr>
          <th scope="col" class="ps-0 text-start">
            Name
          </th>
          <th scope="col" class="text-end">
            Price
          </th>
          <th
            scope="col" 
            class="text-center d-none d-sm-table-cell"
            v-if="selectedScope === 'shortterm'"
          >
            Last 7d
          </th>
          <th
            scope="col" 
            class="text-center d-none d-md-table-cell"
            v-else
          >
            Last 7d
          </th>
          <th 
            scope="col" 
            class="text-center d-none d-sm-table-cell"
            v-if="selectedScope === 'mediumterm'"
          >
            Last 30d
          </th>
          <th 
            scope="col" 
            class="text-center d-none d-md-table-cell"
            v-else
          >
            Last 30d
          </th>
          <th 
            scope="col" 
            class="text-center d-none d-sm-table-cell"
            v-if="selectedScope === 'longterm'"
          >
            Last 200d
          </th>
          <th 
            scope="col" 
            class="text-center d-none d-md-table-cell"
            v-else
          >
            Last 200d
          </th>
          <th 
            scope="col" 
            class="text-end d-none d-sm-table-cell"
            v-if="selectedScope === 'shortterm'"
          >
            L7d %Δ
          </th>
          <th 
            scope="col" 
            class="text-end d-none d-xl-table-cell"
            v-else
          >
            L7d %Δ
          </th>
          <th 
            scope="col" 
            class="text-end d-none d-sm-table-cell"
            v-if="selectedScope === 'mediumterm'"
          >
            L30d %Δ
          </th>
          <th 
            scope="col" 
            class="text-end d-none d-xl-table-cell"
            v-else
          >
            L30d %Δ
          </th>
          <th 
            scope="col" 
            class="text-end d-none d-sm-table-cell"
            v-if="selectedScope === 'longterm'"
          >
            L200d %Δ
          </th>
          <th 
            scope="col" 
            class="text-end d-none d-xl-table-cell"
            v-else
          >
            L200d %Δ
          </th>
          <th scope="col" class="pe-0 text-end">
            Rating*
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="itm of apiData" 
          :key="itm.symbol"
          class="text-nowrap"
        >
          <td class="td-truncate ps-0 text-start">
            <div>
              <h5 class="font-size-14 mb-1">
                <router-link :to="{ name: `${assetType}-symbol`, params: { symbol: itm.symbol }}">
                  {{ itm.symbol }}
                </router-link>
              </h5>
              <p class="text-muted mb-0">{{ itm.name }}</p>
            </div>
          </td>
          <td>
            <div class="text-end">
              <h5 class="font-size-14 mb-0">{{ formatPrice(itm.latest_price) }}</h5>
            </div>
          </td>
          <td
            class="d-none d-sm-table-cell"
            v-if="selectedScope === 'shortterm'"
          >
            <div class="text-center" style="width: 80px">
              <apexchart
                class="apex-charts"
                height="30"
                width="60"
                type="area"
                dir="ltr"
                :series="toSparklineSeries(itm.sparklines.close_last_7_days, itm.symbol)"
                :options="getSparklineChartOptions(itm, 7)"
              ></apexchart>
            </div>
          </td>
          <td
            class="d-none d-md-table-cell"
            v-else
          >
            <div class="text-center" style="width: 80px">
              <apexchart
                class="apex-charts"
                height="30"
                width="60"
                type="area"
                dir="ltr"
                :series="toSparklineSeries(itm.sparklines.close_last_7_days, itm.symbol)"
                :options="getSparklineChartOptions(itm, 7)"
              ></apexchart>
            </div>
          </td>
          <td
            class="d-none d-sm-table-cell"
            v-if="selectedScope === 'mediumterm'"
          >
            <div class="text-center" style="width: 80px">
              <apexchart
                class="apex-charts"
                height="30"
                width="60"
                type="area"
                dir="ltr"
                :series="toSparklineSeries(itm.sparklines.close_last_30_days, itm.symbol)"
                :options="getSparklineChartOptions(itm, 30)"
              ></apexchart>
            </div>
          </td>
          <td
            class="d-none d-md-table-cell"
            v-else
          >
            <div class="text-center" style="width: 80px">
              <apexchart
                class="apex-charts"
                height="30"
                width="60"
                type="area"
                dir="ltr"
                :series="toSparklineSeries(itm.sparklines.close_last_30_days, itm.symbol)"
                :options="getSparklineChartOptions(itm, 30)"
              ></apexchart>
            </div>
          </td>
          <td
            class="d-none d-sm-table-cell"
            v-if="selectedScope === 'longterm'"
          >
            <div class="text-center" style="width: 80px">
              <apexchart
                class="apex-charts"
                height="30"
                width="60"
                type="area"
                dir="ltr"
                :series="toSparklineSeries(itm.sparklines.close_last_200_days, itm.symbol)"
                :options="getSparklineChartOptions(itm, 200)"
              ></apexchart>
            </div>
          </td>
          <td
            class="d-none d-md-table-cell"
            v-else
          >
            <div class="text-center" style="width: 80px">
              <apexchart
                class="apex-charts"
                height="30"
                width="60"
                type="area"
                dir="ltr"
                :series="toSparklineSeries(itm.sparklines.close_last_200_days, itm.symbol)"
                :options="getSparklineChartOptions(itm, 200)"
              ></apexchart>
            </div>
          </td>
          <td 
            class="d-none d-sm-table-cell"
            v-if="selectedScope === 'shortterm'"
          >
            <div class="text-end" style="width: 70px">
              <h5 class="font-size-14 text-muted mb-0">
                {{ formatPercent(itm.rel_deltas.rel_delta_7_days) }}
              </h5>
            </div>
          </td>
          <td 
            class="d-none d-xl-table-cell"
            v-else
          >
            <div class="text-end" style="width: 70px">
              <h5 class="font-size-14 text-muted mb-0">
                {{ formatPercent(itm.rel_deltas.rel_delta_7_days) }}
              </h5>
            </div>
          </td>
          <td 
            class="d-none d-sm-table-cell"
            v-if="selectedScope === 'mediumterm'"
          >
            <div class="text-end" style="width: 70px">
              <h5 class="font-size-14 text-muted mb-0">
                {{ formatPercent(itm.rel_deltas.rel_delta_30_days) }}
              </h5>
            </div>
          </td>
          <td 
            class="d-none d-xl-table-cell"
            v-else
          >
            <div class="text-end" style="width: 70px">
              <h5 class="font-size-14 text-muted mb-0">
                {{ formatPercent(itm.rel_deltas.rel_delta_30_days) }}
              </h5>
            </div>
          </td>
          <td 
            class="d-none d-sm-table-cell"
            v-if="selectedScope === 'longterm'"
          >
            <div class="text-end text-truncate" style="width: 70px">
              <h5 class="font-size-14 text-muted mb-0">
                {{ formatPercent(itm.rel_deltas.rel_delta_200_days) }}
              </h5>
            </div>
          </td>
          <td 
            class="d-none d-xl-table-cell"
            v-else
          >
            <div class="text-end text-truncate" style="width: 70px">
              <h5 class="font-size-14 text-muted mb-0">
                {{ formatPercent(itm.rel_deltas.rel_delta_200_days) }}
              </h5>
            </div>
          </td>
          <td class="pe-0 text-end">
            <div class="text-end" style="width: 95px">
              <span :class="setBadgeClass(itm, scopes[selectedScope]['snake'])">
                {{ itm['score_labels']['score_label_' + scopes[selectedScope]['snake']] }}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
